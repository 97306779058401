export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'email', label: '' },
      { key: 'name', label: 'Voor / achternaam', sortable: true },
      { key: 'role', sortable: true },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
  },
}
