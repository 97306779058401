<template>
  <b-card>
    <b-row>
      <b-col class="col-lg-8 offset-lg-2">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refCompanySettingsTabForm"
        >
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="Language"
                label-for="language"
              >
                <v-select
                  id="language"
                  v-model="settings.language"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Nederlands', 'Engels']"
                  class=""
                  required
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Timezone"
              rules="required"
            >
              <b-form-group
                label="Timezone"
                label-for="timezone"
              >
                <v-select
                  id="timezone"
                  v-model="settings.timezone"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['(GMT+00:00) UTC', '(GMT+01:00) Amsterdam']"
                  class=""
                  required
                  :clearable="false"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Currency"
                  rules="required"
                >
                  <b-form-group
                    label="Currency"
                    label-for="currency"
                  >
                    <v-select
                      id="currency"
                      v-model="settings.currency"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="['Euro (€)']"
                      class=""
                      required
                      :clearable="false"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="4">
                <validation-provider
                  #default="validationContext"
                  name="Time format"
                  rules="required"
                >
                  <b-form-group
                    label="Time format"
                    label-for="time-format"
                  >
                    <v-select
                      id="time-format"
                      v-model="settings.timeformat"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="['15:00', '3:00 PM']"
                      class=""
                      required
                      :clearable="false"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="4">
                <validation-provider
                  #default="validationContext"
                  name="First day of week"
                  rules="required"
                >
                  <b-form-group
                    label="First day of week"
                    label-for="first-day"
                  >
                    <v-select
                      id="first-day"
                      v-model="settings.firstday"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="['Zondag', 'Maandag']"
                      class=""
                      required
                      :clearable="false"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      settings: {
        language: 'Nederlands',
        timezone: '(GMT+01:00) Amsterdam',
        currency: 'Euro (€)',
        timeformat: '15:00',
        firstday: 'Maandag',
      },
      required,
    }
  },
  setup() {
    const data = ref({})
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(this.company))
    }
    const {
      refCompanySettingsTabForm,
      resetForm,
      getValidationState,
    } = formValidation(resetData)

    return {
      refCompanySettingsTabForm,
      resetForm,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
