<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Algemene instellingen</span>
      </template>
      <general-settings-tab />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Gebruikersaccounts</span>
      </template>
      <accounts-list />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import GeneralSettingsTab from './GeneralSettingsTab.vue'
import AccountsList from './accounts/AccountsList.vue'

export default {
  components: {
    AccountsList,
    GeneralSettingsTab,
    BTabs,
    BTab,
  },
}
</script>
