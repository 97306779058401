<template>
  <div>
    <table-list
      ref="tableList"
      :fields="fields"
      module="employees"
      :show-search="true"
      :sort-by="sortBy"
      sidebar-title="Add new employee"
      :show-entries-choice="false"
    />
  </div>
</template>

<script>
import {
  BRow,
} from 'bootstrap-vue'
import { computed, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import accountStoreModule from '@/views/settings/general/accounts/accountStoreModule'

export default {
  components: {
    TableList,
  },
  data() {
    return {
      company: {
        name: null,
        location: {
          address: null,
          zipcode: null,
          city: null,
          phonenumber: null,
          email: null,
        },
      },
      required,
    }
  },
  setup() {
    const ACCOUNT_STORE_MODULE_NAME = 'account'

    // Register module
    if (!store.hasModule(ACCOUNT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.account.tableFields)
    const sortBy = computed(() => store.state.account.sortBy)

    return {
      fields,
      sortBy,
    }
  },
}
</script>

<style>

</style>
